<template>
  <div id="learnList">
    <div class="ListWrap">
      <div class="ListInfo" v-for="(item, index) in dataList" :key="index">
        <img :src="item.src" alt="" />
        <p class="infoTitle">{{ item.title }}</p>
        <p class="infoTitle">{{ item.fraction }}</p>
      </div>
    </div>
    <slot name="tip"> </slot>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less">
#learnList {
  width: 100%;
  background: #ff816c;
  position: relative;
  height: 16rem;
  .ListWrap {
    width: 94%;
    position: absolute;
    left: calc(50% - 47%);
    top: 1.5rem;
    padding: 1.3rem 0;
    background: #fff;
    border-radius: 12px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .ListInfo {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      img {
        width: 55px;
      }
      .infoTitle {
        font-size: 1.5rem;
        padding: 0.3rem;
        color: #222222;
        white-space: nowrap;
      }
    }
  }
}
</style>