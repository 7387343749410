<template>
  <div id="learn">
    <BottomNav />
    <van-nav-bar title="订单" :border="false">
      <template #right>
        <van-icon name="search" size="18" />
      </template>
    </van-nav-bar>
    <!-- <TopBar class="background">
      <template #leftInfo>
       <svg t="1601170867949" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4264" width="24" height="24"><path d="M608.992 148.992L277.984 489.984q-8.992 8.992-8.992 21.504t8.992 22.496l331.008 340.992q8.992 8 20.992 8t20.992-8.992 8.992-20.992-8.992-20.992l-312-320 312-320q8.992-8.992 8.992-20.992t-8.992-20.992-20.992-8.992-20.992 8z" p-id="4265" fill="#ffffff"></path></svg>
      </template>
      <template #middleInfo> <span class="topTitle">学习</span> </template>
      <template #rightInfo>
        <svg
          t="1601018941020"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3117"
          width="24"
          height="24"
        >
          <path
            d="M197.844997 512m-74.694189 0a72.993 72.993 0 1 0 149.388379 0 72.993 72.993 0 1 0-149.388379 0Z"
            p-id="3118"
            fill="#ffffff"
          ></path>
          <path
            d="M512 512m-74.694189 0a72.993 72.993 0 1 0 149.388379 0 72.993 72.993 0 1 0-149.388379 0Z"
            p-id="3119"
            fill="#ffffff"
          ></path>
          <path
            d="M826.155003 512m-74.694189 0a72.993 72.993 0 1 0 149.388379 0 72.993 72.993 0 1 0-149.388379 0Z"
            p-id="3120"
            fill="#ffffff"
          ></path>
        </svg>
      </template>
    </TopBar> -->

    <LearnList :dataList="Learnlist" style="height: 9rem" />
    <Notices />
    <Recommend />
  </div>
</template>
<script>
import BottomNav from "@/components/NavBar/BottomNav.vue";
import TopBar from "@/components/home/TopBar.vue";
import LearnList from "@/components/learn/LearnList.vue";
import Notices from "@/components/learn/Notices.vue";
import Recommend from "@/components/learn/Recommend.vue";

export default {
  data() {
    return {
      Learnlist: [
        {
          title: "官方公告",
          src: require("@/assets/images/learnList/list1.png"),
        },
        {
          title: "最新公告",
          src: require("@/assets/images/learnList/list2.png"),
        },
        {
          title: "课程培训",
          src: require("@/assets/images/learnList/list3.png"),
        },
      ],
    };
  },
  components: {
    BottomNav,
    TopBar,
    LearnList,
    Notices,
    Recommend,
  },
};
</script>
<style lang="less">
#learn {
  background: #f0f0f0;
  .topTitle {
    font-size: 1.8rem;
    font-weight: 400;
  }
}
</style>