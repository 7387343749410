<template>
  <div id="recommend">
    <!-- 推荐 -->
    <div class="recommendWrap">
      <p class="column">推荐</p>
      <div class="articleList" v-for="(item, index) in 9" :key="index">
        <div class="articlePic"></div>
        <div class="articleInfo">
          <h4 class="articTitle">
            电工作业安全，有奖讨论火热进行中火热进行中火热进行中火热进行中
          </h4>
          <p class="articTag">
            安全达人快来 <span class="kindTag labelKind1">必读</span><span class="kindTag labelKind2">精选</span>
          </p>
          <p class="articTime">
            <span>2020-09-29 15:32</span> <span>1550阅读 7赞</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>>
<script>
export default {};
</script>>
<style lang="less">
#recommend {
  .recommendWrap {
    width: 100%;
    margin: 0 auto;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    box-sizing: border-box;
    background: #fff;
    margin-bottom: 50px;
    .column {
      font-size: 1.6rem;
      font-weight: 600;
      margin: 1rem 0;
    }
    .articleList {
      padding: 1.6rem 0;
      border-top: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      .articlePic {
        width: 100%;
        height: 12rem;
        border-radius: 0.5rem;
        background: url(https://cdn.yishifu.com/workerHead/20201104103958rx8lDBVg.jpeg)
          no-repeat center / cover;
      }
      .articleInfo {
        line-height: 2.5rem;
        .kindTag {
          margin-left: 1rem;
          font-size: 0.8rem;
          padding: 0.2rem;

          &.labelKind1 {
            background: #f5c155;
            color: #fff;
            border: 1px solid #f5c155;
            border-radius: 2px;
          }
          &.labelKind2 {
            background: #eb605a;
            color: #fff;
            border: 1px solid #eb605a;
            border-radius: 2px;
          }
        }
        .articTitle {
          margin-top: 1rem;
          font-size: 1.5rem;
          color: #313131;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
        .articTag {
          font-size: 1.4rem;
          color: #686868;
        }
        .articTime {
          color: #8f8f8f;
          font-size: 1.4rem;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>