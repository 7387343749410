<template>
  <div id="notices">
    <van-notice-bar left-icon="volume-o" :scrollable="false" background="#fff" color="#222222">
  <span class="textPublic">违规曝光平台</span>
      <van-swipe
        vertical
        class="notice-swipe"
        :autoplay="2000"
        :show-indicators="false"
      >
        
        <van-swipe-item>李师傅禁止接单10天</van-swipe-item>
        <van-swipe-item>王师傅禁止接单10天</van-swipe-item>
        <van-swipe-item>李师傅禁止接单12天</van-swipe-item>
      </van-swipe>
    </van-notice-bar>
  </div>
</template>>
<script>
export default {};
</script>>
<style lang="less">
#notices {
  margin: 5rem 0 1rem 0;
  .van-notice-bar {
    position: relative;
    .textPublic {
      position: absolute;
      top: calc(50% - 30%);
      left: 1rem;
    }
  }
   .notice-swipe {
    height: 40px;
    line-height: 40px;
    margin-left: 14rem;
    color: coral;
    font-size: 1.35rem;
  }
}
</style>>